import "./App.css";
import Header from "./components/header/Header.js";
import Content from "./components/content/Content.js";
import Footer from "./components/footer/Footer.js";



function App() {
    return (
        <>
            <div className="wrapper">
                <Header/>
                <Content greeting="true"/>
            </div>
            <Footer/>
        </>
    );
}


export default App;
