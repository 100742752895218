import React, { useState } from 'react';
import StartButton from "../start_button/StartButton.js"
import Form from "../form/Form.js"
import "./Content.css";




function Content(props) {
    const [greeting, setGreeting] = useState(props.greeting === "true");

    const GreetingText = (
        <article>
            <h1>
                Надоела работа?<br />
                Мечтаешь открыть свое дело?<br />
                <strong>Сгенерируй свой бизнес!</strong>
            </h1>
            <hr />

            <p>
                Генератор бизнес-идей поможет вам создать свой бизнес.
            </p>
            <p>
                Генератор использует самую совершенную на сегодняшний день нейронную сеть <strong>ChatGPT</strong>,
                обученную на сотнях миллионов статей, чтобы на основе ваших возможностей и интересов сгенерировать
                наиболее подходящую Бизнес-идею специально для вас!
            </p>
            <p>
                Заполни форму&nbsp;— укажи свои интересы, свой бюджет, и получи <strong>3&nbsp;бесплатные бизнес-идеи</strong>,
                из которых ты сможешь выбрать лучшую и скачать описание, презентацию и краткий бизнес-план.
            </p>

            <div>
                <StartButton text="Начать" clickHandler={() => setGreeting(false)} />
            </div>
        </article>
    );

    if (greeting) {
        return GreetingText;
    } else {
        return <Form/>;
    }
}


export default Content;
