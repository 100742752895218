import "./Footer.css";


function Footer() {
    return (
        <footer>
            <span>&copy; 2022 Business Master</span>
        </footer>
    );
}


export default Footer;
