import "./MainMenu.css";


function MainMenu() {
    return (
        <nav>
            <input id="menu_toggle" type="checkbox" />
            <label className="menu_btn" htmlFor="menu_toggle">
                <span className="menu_btn_opened">меню ☰</span>
                <span className="menu_btn_closed">закрыть ✕</span>
            </label>

            <ul className="menu">
                <li>
                    <a href="/" title="Описание проекта">О проекте</a>
                </li>
                <li>
                    <a href="https://forms.yandex.ru/cloud/63b9bb9584227c6255bd30de/" title="Подписка">Подписка</a>
                </li>
                <li>
                    <a href="https://fantalks.io/r/business_master" title="Договориться о сотрудничестве">Сотрудничество</a>
                </li>
                <li>
                    <a href="https://fantalks.io/r/business_master" title="Связаться с разработчиками">Контакты</a>
                </li>
            </ul>
        </nav>
    );
}


export default MainMenu;
