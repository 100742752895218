import MainMenu from "./../main-menu/MainMenu.js"
import "./Header.css";
import logo from "./../../style/logo.png"
import logo_small from "./../../style/logo-small.png"


function Header() {
    return (
        <header>
            <img src={logo} className="logo" alt="Business Master" />
            <img src={logo_small} className="logo-small" alt="Business Master" />

            <MainMenu/>
        </header>
    );
}


export default Header;
