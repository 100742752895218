import "./StartButton.css";


function StartButton(props) {
    return (
        <button onClick={() => props.clickHandler()}>{props.text}</button>
    );
}


export default StartButton;
