import React, { useState, useEffect } from "react";
import "./Form.css";
import loader from "../../style/loader_6.gif"


function Form() {
    const MAX_REQUESTS = 2;

    const [occupation, setOccupation] = useState("")
    const [budget, setBudget] = useState("")
    const [keywords, setKeywords] = useState("")

    const handlers = {
        occupation: setOccupation,
        budget: setBudget,
        keywords: setKeywords,
    }

    const [requests_count, setRequestsCount] = useState(0);
    const updateRequestsCount = count => {
        localStorage.setItem("requests_count", count);
        setRequestsCount(count);
    }

    useEffect(() => {
        const storage_requests_count = localStorage.getItem("requests_count");
        if (storage_requests_count) {
            setRequestsCount(Number.parseInt(storage_requests_count));
        }
    }, [requests_count]);

    const inputChange = (event) => {
        const target = event.target;
        handlers[target.name](target.value);
    }

    const [progress, setProgress] = useState(false);
    const [ideas, setIdeas] = useState("")

    function delay(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    const submitHandler = async (event) => {
        event.preventDefault();

        if (requests_count >= MAX_REQUESTS) {
            return;
        }

        setProgress(true);

        const data = {
            "field": occupation,
            "budget": budget,
            "keywords": keywords,
        }
        console.log(`Send request: ${JSON.stringify(data, null, 4)}`);

        const response = await fetch("https://zft.su/api/", {
            method: 'POST',
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        // Start MOCK MOCK MOCK
        /*await delay(5000);
        const response = {
            status: 200,
            statusText: "OK",
            ok: true,
            json: async () => JSON.parse('{"first_idea":"1. &quot;Автоматизированная система упаковки яиц&quot;: разработка автоматической системы упаковки яиц для мелких производителей с использованием бюджета в размере 5 000 рублей, которая могла бы помочь в точном распределении и упаковке яиц, что позволило бы им беспрепятственно экспортировать их в другие места.","second_idea":"2. Приложение для доставки мяса: разработка мобильного приложения, подавающего свежее мясо потребителям.","third_idea":"3. Автоматизированная онлайновая система покупок: использование 5000 рублей для создания автоматической онлайновой системы покупок, которая позволит клиентам легко заказывать свои продукты в онлайновом режиме и доставлять товары на порог, тем самым сокращая время и усилия, затраченные на покупку продуктов."}'),
        }*/
        // End MOCK MOCK MOCK
        console.log(`Response ${response.status} ${response.statusText}`)

        if (response.ok) {
            updateRequestsCount(requests_count + 1);
            console.log(`OK`);
            try {
                const responseObject = await response.json();
                console.log(responseObject);

                if ("first_idea" in responseObject) {
                    setIdeas(
                        responseObject.first_idea + "\n\n" +
                            responseObject.second_idea + "\n\n" +
                            responseObject.third_idea
                    );
                } else if ("text" in responseObject) {
                    setIdeas(responseObject.text);
                }
            } catch(e) {
                console.error(`Error parsing response`, e);
                updateRequestsCount(MAX_REQUESTS);
            }
        }

        setProgress(false);
    }

    // https://forms.yandex.ru/cloud/63b9bb9584227c6255bd30de/
    return (
        <article>
            <form onSubmit={submitHandler}>
                <fieldset>
                    <legend>Сгенерировать Бизнес-Идею</legend>

                    <label>
                        В какой области создать бизнес:
                        <input
                                type="text"
                                name="occupation"
                                value={occupation}
                                onChange={inputChange}
                                />
                    </label>

                    <label>
                        Ваш бюджет в рублях:
                        <input
                            type="text"
                            name="budget"
                            placeholder="необязательно"
                            value={budget}
                            onChange={inputChange}
                        />
                    </label>

                    <label>
                        Ключевые слова через пробел:
                        <input
                            type="text"
                            name="keywords"
                            placeholder="необязательно"
                            value={keywords}
                            onChange={inputChange}
                        />
                    </label>

                    {progress
                        ? <img className="loader" src={loader} alt="Ожидайте..." />
                        : requests_count < MAX_REQUESTS
                            ? <input type="submit" value="Генерировать!" />
                            : <div className="limit">
                                <p>Извините, но Вы достигли максимального количества бесплатных запросов.</p>
                                <p>
                                    В настоящее время платная версия сервиса все еще находится в разработке, но Вы можете &nbsp;
                                    <a href="https://forms.yandex.ru/cloud/63b9bb9584227c6255bd30de/"
                                            title="Пидписаться на рассылку о запусске Генератора бизнес-идей">подписаться на рассылку</a>,
                                    чтобы узнать о запуске полной версии Генератора бизнес-идей первым!
                                </p>
                            </div>
                    }
                </fieldset>
            </form>

            {ideas !== "" && !progress
                ? <>
                    <section className="ideas">
                        <h2>Ваши бизнес-идеи:</h2>
                        <hr/>
                        <p dangerouslySetInnerHTML={{ __html: ideas }} />
                    </section>
                </>
                : <></>
            }
        </article>
    );
}


export default Form;
